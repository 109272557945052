import React from "react";
import Layout from "../components/layout";
import AddressAndTransport from "../components/AddressAndTransport";
import ContactForm from "../components/contactForm";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { parseNestedHTML } from "../Utils";
import HTMLReactParser from "html-react-parser";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import SafeHtmlParser from "../components/safe-html-parser";
import GroupForm from "../components/group-form";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};
const SocialTeamActivitiesEastbourn = () => {
	const data = useStaticQuery(graphql`
		query {
			wpSocialGroup {
				socialGroupFileds {
					address {
						btnText
						title
						location {
							lat
							lng
						}
					}
					customizedPackage {
						body
						btnText
						subheading
						title
					}
					heroSection {
						btnText
						title
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					privateRoom {
						body
						btnText
						title
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					vehicleStatus {
						body
						title
						icon {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					venueForCelebration {
						body
						heading1
						heading1Body
						title

						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					whyChooseNinjaArena {
						btnText
						slider {
							image {
								node {
									altText
									localFile {
										publicURL
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						reasons {
							label
						}
						title
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const siteUrl = data.site.siteMetadata.siteUrl;
	console.log(data);
	const SocialGroup = data.wpSocialGroup.socialGroupFileds;
	const seoFields = data.wpSocialGroup.seoFields;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Social Team Activities Eastbourne",
				item: {
					url: `${siteUrl}/social-team-activities-eastbourne`,
					id: `${siteUrl}/social-team-activities-eastbourne`,
				},
			},
		],
	};
	return (
		<div>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/social-team-activities-eastbourne`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<section className="position-relative ">
					<div className="position-relative hero-carousal">
						<div
							style={{
								zIndex: 1,
								background:
									"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
								borderTop: "1px solid #FFF",
								borderBottom: "1px solid #FFF",
							}}
							className="position-absolute h-100 w-100"
						>
							<Container className="h-100">
								<Row className="align-items-center h-100">
									<Col>
										<div className="h-100 ">
											<div className="stylishText text-uppercase  ">
												<SafeHtmlParser
													htmlContent={SocialGroup.heroSection.title}
												/>
											</div>
											<Button
												href="#form"
												className="text-white text-uppercase w-100 w-md-auto  fs-5 px-4 py-2"
											>
												{SocialGroup.heroSection.btnText}
											</Button>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
						<div className="slider-container">
							<Slider {...settings}>
								{SocialGroup.heroSection.slider.map((slider, i) => (
									<div key={i}>
										<GatsbyImage
											className="images-height"
											image={
												slider.image?.node.localFile?.childImageSharp
													?.gatsbyImageData
											}
											style={{
												objectFit: "cover",
												width: "100%",
											}}
											alt={slider.image?.node?.altText}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</section>
				<section className="py-5 pt-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="display-5 pb-3 text-uppercase">
									{SocialGroup.venueForCelebration.title}
								</h2>

								<div>
									<SafeHtmlParser
										htmlContent={SocialGroup.venueForCelebration.body}
									/>
								</div>

								<div className="w-100 py-5">
									<GatsbyImage
										image={
											SocialGroup.venueForCelebration.image?.node.localFile
												?.childImageSharp?.gatsbyImageData
										}
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={SocialGroup.venueForCelebration.image?.node?.altText}
									/>
								</div>
								<h3 className="fs-4">
									{SocialGroup.venueForCelebration.heading1}
								</h3>
								<div>
									<SafeHtmlParser
										htmlContent={SocialGroup.venueForCelebration.heading1Body}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="mb-5">
					<Container>
						<Row className="align-items-center gy-5">
							<Col lg={{ span: 6, order: "last" }}>
								<div className="slider-container">
									<Slider {...settings}>
										{SocialGroup.whyChooseNinjaArena.slider.map((slider, i) => (
											<div key={i}>
												<GatsbyImage
													image={
														slider.image?.node.localFile?.childImageSharp
															?.gatsbyImageData
													}
													alt={slider.image?.node?.altText}
												/>
											</div>
										))}
									</Slider>
								</div>
							</Col>
							<Col lg={6}>
								<h2 className="display-5 pb-3 text-uppercase">
									{SocialGroup.whyChooseNinjaArena.title}
								</h2>
								<div className="d-md-none">
									{SocialGroup.whyChooseNinjaArena.reasons.map(
										({ label }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={1}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "15px", maxWidth: "15px" }}
												/>
												<p className="text-white pt-1">{label}</p>
											</Stack>
										)
									)}
								</div>
								<div className="d-none d-md-block">
									{SocialGroup.whyChooseNinjaArena.reasons.map(
										({ label }, i) => (
											<Stack
												direction="horizontal"
												className="align-items-start"
												gap={2}
												key={i}
											>
												<NinjaStarSVG
													style={{ minWidth: "20px", maxWidth: "20px" }}
												/>
												<p className="text-white pt-1">{label}</p>
											</Stack>
										)
									)}
								</div>
								<Button
									href="#form"
									className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
								>
									{SocialGroup.whyChooseNinjaArena.btnText}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Row className="align-items-center gx-0">
						<Col lg={6}>
							<div
								style={{
									position: "relative",
									display: "inline-block", // Or 'block' depending on your layout
								}}
							>
								<div
									style={{
										position: "absolute",
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
										background:
											"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
										pointerEvents: "none", // Allows interaction with the GatsbyImage
										zIndex: 1, // Ensures the overlay is above the image
									}}
								></div>
								<GatsbyImage
									image={
										SocialGroup.privateRoom.image?.node.localFile
											?.childImageSharp?.gatsbyImageData
									}
									className="w-100"
									style={{
										objectFit: "cover",
										width: "100%",
									}}
									alt={SocialGroup.privateRoom.image?.node?.altText}
								/>
							</div>
						</Col>
						<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
							<h3 className="display-5  text-uppercase text-white ">
								{SocialGroup.privateRoom.title}
							</h3>
							<div className="">
								<SafeHtmlParser htmlContent={SocialGroup.privateRoom.body} />
							</div>
							<p className="text-white">{SocialGroup.privateRoom.subheading}</p>
							<Button
								href="#form"
								className="text-white text-uppercase w-100 w-md-auto  fs-5 px-4 py-2"
							>
								{SocialGroup.privateRoom.btnText}
							</Button>
						</Col>
					</Row>
				</section>

				<section className="pb-5 pb-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8}>
								<Stack gap={3} className="align-items-center text-center">
									<h2 className="display-5 pb-0 text-uppercase">
										{SocialGroup.customizedPackage.title}
									</h2>

									<div>
										<SafeHtmlParser
											htmlContent={SocialGroup.customizedPackage.body}
										/>
									</div>
									<p className="text-white">
										{SocialGroup.customizedPackage.subheading}
									</p>
									<Button
										href="#form"
										className="text-white text-uppercase w-100 w-md-auto mt-2 fs-5 px-4 py-2"
									>
										{SocialGroup.customizedPackage.btnText}
									</Button>
								</Stack>
							</Col>
						</Row>
					</Container>
				</section>

				<section>
					<AddressAndTransport
						buttonLink="#form"
						address={SocialGroup.address}
						vehiclesStatus={SocialGroup.vehicleStatus}
					/>
				</section>
				<section className="py-5 py-lg-7" id="form">
					<Container>
						<h2 className="display-5 pb-3 text-uppercase text-center">
							Enquire now
						</h2>
						<GroupForm page="Social Team Activities Eastbourne" />
					</Container>
				</section>
			</Layout>
		</div>
	);
};

export default SocialTeamActivitiesEastbourn;
